@use "../../scss/abstracts/" as *;

.main-apercu {
  transition: all 0.2s ease-in-out;
  .preview-wrapper {
    display: flex;
    padding: 15px;
    background: #f8f8f8;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
   
    .main-content-apercu {
      position: relative;
      pointer-events: none;
      padding: 0 0 0 30px;
      width: calc(100% - 250px);
    }
    @media (max-width: 1199px) { 
      padding: 0; 
      width: 90%;
      flex: 0 0 auto;
      max-width: 90%;
      .main-content-apercu { 
        width: 100%;
        padding: 0;
      }
    }
    .btn {
      &.btn-creat-apercu {
        min-height: 50px !important;
      }
    }
    .sidebar-apercu {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      color: #4e4d4f;
      width: 250px;
      background-color: #fff;
      z-index: 100;
      pointer-events: none;
      @media (max-width: 1199px) {
        display: none;
      }
      .logo-apercu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 30px;
        svg,
        img {
          width: 185px;
          height: auto;
        }
      }
      ul {
        position: relative;
        padding: 15px 0;
        margin: 0 0 20px;
        overflow: hidden;
        li {
          padding: 12px 35px 12px 25px;
          position: relative;

          .nav-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            border-radius: 25px;
            padding: 8px 5px 8px 10px;
            color: #b0b0b0;
            font-size: 1rem;
            transition: color 0.3s ease-in-out;
            .icon-nav {
              --size: 25px;
              width: var(--size);
              height: var(--size);
              margin-right: 0.6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-height: 25px;
              }
            }
          }

          &.active {
            a {
              font-weight: 700;
              color: $white;
              // background: $primaryColor;
              // box-shadow: inset 0px 4px 6px 0px darken($primaryColor, 12);

              .icon-nav {
                img {
                  display: none;
                }
                background: url(../../assets/images/svg/icon-nav-4-w.svg)
                  no-repeat center;
                background-size: 100%;
              }
            }

            &::before {
              content: "";
              position: absolute;
              height: 6px;
              width: 6px;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              // background-color: $primaryColor;
              border-radius: 50%;
              z-index: 1;

              @include tablet {
                display: none;
              }
            }

            &::after {
              content: "";
              position: absolute;
              height: 60px;
              width: 60px;
              right: -40px;
              top: 5px;
              transform: rotate(45deg);
              background-color: $bodyBg;
              border-radius: 15px;
              z-index: 0;
              // box-shadow: inset 2px 2px 7px 3px lighten($secondaryColor, 32%);

              @include tablet {
                display: none;
              }
            }
          }
        }
      }
      .logout-sidbar {
        margin-top: 30px;
        padding: 0;
        position: relative;
        &:before {
          content: "";
          height: 1px;
          background-color: rgba(176, 176, 176, 0.45);
          top: 0;
          left: 35px;
          right: 35px;
          position: absolute;
        }
      }
    }

    .banner-apercu {
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding: 15px 15px 15px 30px;
      flex-wrap: wrap;
      box-sizing: border-box;
      border-radius: 25px;
      position: relative;
      min-height: 200px;
      align-items: center;
      // box-shadow: 0 5px 20px lighten($secondaryColor, 15);
      .Banner-text {
        width: 60%;
        position: relative;
        z-index: 99;
      }
      h2 {
        font-size: 1.5rem;
        font-family: $tt-demibold;
        color: $white !important;
        margin-bottom: 25px;
        line-height: 35px;
      }
      .Banner-img {
        // background: url(../../assets/images/bg-banner-filter.svg) no-repeat bottom;
        background-size: 90%;
        max-width: 250px;
        position: absolute;
        right: 15px;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding: 0 5px 0 0;
        img {
          max-width: 160px;
          height: auto;
        }
        @media (max-width: 1500px) {
          max-width: 250px;
          img {
            max-width: 160px;
          }
        }
      }
      p {
        margin: 0px 50px 0 0;
        font-size: 1rem;
        line-height: 20px;
        color: white;
      }
    }
    .user-apercu {
      height: 100%;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      .user-info {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 30px;
        height: 90px;
        width: 90px;
        background: transparent;
        // background: url(../../assets/images/cercle-bg.png) no-repeat;
        // background-size: 100%;
        position: relative;
        .bgCercle {
          position: absolute;
          width: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        @media (max-width: 991px) {
          height: 150px;
          width: 150px;
        }
        &__img {
          --size: 60px;
          width: var(--size);
          height: var(--size);
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
          }
        }

        &__name {
          padding: 0 $spacing;
          font-size: $fontSizeMedium;
          font-weight: 700;

          @include tablet {
            padding: 0 $mobileSpacing;
          }
        }
      }
      @media (max-width: 576px) {
        position: static;
        text-align: right;
        .btn {
          margin: 0 0 12px;
        }
      }
    }
    .table-apercu {
      thead {
        // color: $primaryColor;
        // background: lighten($primaryColor, 35);
        border-radius: 30px;
        border-bottom: none !important;
        margin-bottom: 5px;

        th {
          font-size: 1.15rem;
          font-family: $tt-demibold;
          padding: 15px 5px;
          text-align: center;
          border: none;
          &:first-child {
            border-radius: 30px 0 0 30px;
          }
          &:last-child {
            min-width: 130px;
            justify-content: center;
            border-radius: 0 30px 30px 0;
          }
        }
      }
      tbody {
        border: none !important;
        td {
          padding: 10px 5px;
          border: 1px solid transparent !important;
          font-size: 1.05rem;
          font-family: $tt-medium;
          text-align: center;

          &:last-child {
            min-width: 130px;
            justify-content: center;
          }
        }

        &:hover {
          border-color: #e4eeed !important;
        }
      }
    }
    .top-navbtn {
      padding: 15px 0;
      text-align: right;
    }
  }

  :hover {
    transform: scale(1.4);
    transition: all 0.3s ease-in-out;
  }
}
