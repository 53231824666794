@use "./variables" as *;
body {
  &.modal-open {
    overflow: hidden !important;
  }
}
.modal{
  z-index: 9999;
}
.modal-dialog {
  &.modal-primary {
    .modal-header {
      .modal-title {
        background: lighten($primaryColor, 35);
        color: $primaryColor;
      }
    }

    .react-datepicker__header {
      background-color: $primaryColor !important;
      border-bottom: 1px solid $primaryColor !important;
      color: #fff;
    }
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::after {
      border-bottom-color: $primaryColor !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $primaryColor;
    }
  }
  &.modal-secondary {
    .modal-header {
      .modal-title {
        background: lighten($secondaryColor, 30);
        color: $secondaryColor;
      }
    }
  }
  &.modal-warning {
    .modal-header {
      .modal-title {
        background: lighten($orange, 30);
        color: $orange;
      }
    }
    .react-datepicker__header {
      background-color: $orange !important;
      border-bottom: 1px solid $orange !important;
      color: #fff;
    }
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::after {
      border-bottom-color: $orange !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $orange;
    }
  }
  &.modal-danger {
    .modal-header {
      .modal-title {
        background: lighten($red, 45);
        color: $red;
      }
    }
    .react-datepicker__header {
      background-color: $red !important;
      border-bottom: 1px solid $red !important;
      color: #fff;
    }
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::after {
      border-bottom-color: $red !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $red;
    }
  }
  @media (max-width: 991px) {
    max-width: 90% !important;
  }
  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}
.modal-header {
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: none;
  &.no-header {
    background: transparent !important;
    padding: 0 !important;
    h5 {
      display: none;
    }
  }
  .modal-title {
    border-radius: 50px;
    background: #f5f5f5;
    padding: 15px 20px;
    font-size: 1.8rem;
    min-width: 85%;
    text-align: center;
    font-family: $tt-demibold;
    color: $black;
    @media (max-width: 576px) {
      font-size: 1.25rem;
      line-height: 22px;
    }
  }
  .btn-close {
    position: absolute;
    right: 25px;
    top: 30px;
    width: 20px;
    height: 30px;
    background: url(../../assets/images/svg/close-icon.svg) no-repeat;
    background-size: 100%;
    z-index: 999;
    @media (max-width: 576px) {
      right: 20px; 
      width: 15px;
    }
  }
}
.modal-footer {
  justify-content: center;
  border-top: none;
  padding: 15px 15px 35px;
  .btn {
    min-width: 150px;
    margin: 5px 10px;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
.modal-content {
  border-radius: 40px;
  border: none;
  box-shadow: -20px 30px 99px rgba(114, 114, 114, 0.6);
}
.modal {
  backdrop-filter: blur(5px);
}
.modal-backdrop {
  background-color: #fff;
}
.msg-text {
  font-size: 1.3rem;
  line-height: 24px;
  font-family: $tt-demibold;
  color: $black;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 1.05rem;
    line-height: 23px;
  }
}
.radio-form {
  padding: 25px 5px;
  .inner-radio-form {
    padding: 45px 0;
    border-width: 1px 0 1px 0;
    border-style: dashed;
    border-color: rgba(114, 114, 114, 0.35);
  }
}
.check-box {
  padding: 0 45px 0 0;
  &:last-child {
    padding: 0;
  }
  @media (max-width: 576px) {
    padding: 0 15px 0 0;
  }
}
.form-line {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: center;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  
  }
  @media (max-width: 450px) { 
    .form-label {
     width: 100%;
     margin-right: 0 !important;
     margin-bottom: 15px;
     text-align: center;
  }
  }
}
.checkbox-form {
  padding: 15px;
  .inner-checkbox-form {
    padding: 35px 15px 10px;
    border-width: 1px 0 0 0;
    border-style: dashed;
    border-color: rgba(114, 114, 114, 0.35);
    h4 {
      margin-bottom: 25px;
      font-family: $tt-medium;
      font-size: 1.3rem;
    }
  }
  .check-box {
    margin: 0 0 15px;
    &:last-child {
      padding: 0;
    }
  }
}
.content-form-block {
  padding: 15px 60px;
  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 576px) {
    padding: 15px 0 !important;
  }
}

.liste-tags {
  padding: 45px 15px 0;
  display: flex;
  flex-wrap: wrap;
}
.label-span {
  font-size: 1.1rem;
  font-family: $tt-medium;
}
.line-form-label {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0 15px;
}
.content-creat-cat {
  padding: 15px 65px;
  @media (max-width: 991px) {
    padding: 15px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 950px;
  }
  .modal-apercu {
    max-width: 1200px !important;
  }
}
