@use "../../../scss/abstracts/" as *;

.section-exercise-prev {
  padding: 25px 0 15px;
  h3 {
    color: #b0b0b0;
    border-bottom: 2px dashed #b0b0b0;
    font-size: 27px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
}
.bottom-actions {
  .btn {
    margin: 5px;
  }
}
