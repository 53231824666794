@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}
.page {
  position: relative;
  @media (max-width: 576px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.page-admin {
  @media (max-width: 991px) {
    padding: 45px 0 0 !important;
  }
}
.btn-creat-admin {
  position: absolute;
  top: -82px;
  right: 75px;
  min-width: 240px;
  min-height: 54px !important;
  svg {
    margin: 0 15px 0 0 !important;
  }
  @media (max-width: 991px) {
    top: -20px !important;
    right: 0 !important;
  }
  @media (max-width: 576px) {
    min-width: 150px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.custom-switch {
  display: flex;
  align-items: center;
  span[class*="label"] {
    margin: 0 10px;
    font-weight: 700;
  }
}

.color-label {
  font-size: 0.95rem !important;
}

.modal-body {
  h4 {
    font-family: $tt-medium;
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: normal;
    color: #000;
  }
}
.content-form-block {
  &.content-admin {
    padding: 15px 30px !important;
    @media (max-width: 991px) {
      padding: 15px !important;
    }
    @media (max-width: 576px) {
      padding: 15px 0 !important;
    }
  }
}
.block-apercu {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  // label {
  //   padding-left: 35px;
  // }
  // .layout-apercu {
  //   color: $primaryColor;
  //   box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  //   border-radius: 23px;
  //   min-height: 150px;
  //   padding: 25px;
  //   border: 2px dashed #ccc;
  //   text-align: center;
  //   margin-top: 20px;
  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  //   &.primary {
  //     border-color: $primaryColor;
  //   }
  //   &.secondary {
  //     border-color: $secondaryColor;
  //   }
  //   &.warning {
  //     border-color: $orange;
  //   }
  // }
  @media (max-width: 767px) {
    padding: 35px 15px 10px;
  }
}
.btn {
  &.btn-apercu {
    background: #b0b0b0;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.29);
    border-radius: 60px;
    color: #fff;
    &:hover {
      background: lighten(#b0b0b0, 5);
      box-shadow: none;
    }
  }
}
.apercu-right {
  padding-left: 50px;
  .block-apercu {
    height: 390px;
  }
}

.cabinet-dossiers-num {
  &:hover {
    font-size: 1.5rem;
    text-decoration: underline;
  }
}

.admin-cabinet-custom-switch {
  .react-switch-label {
    border-color: $orange;
    .react-switch-button {
      background: $orange;
    }
  }
  @media (max-width: 576px) {
    justify-content: start;
    .label{
      margin-left: 0 !important;
    }
   }
}

.associe-box {
  .form-line {
    @media (max-width: 576px) {
     justify-content: start;
    }
  }
}
