@use "../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  @include flex(stretch, flex-start);
  flex-direction: column;
  color: $txtColor;
  width: $sideBarWidth;
  background-color: $mainBg;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  transition: all 0.3s;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $mainBg;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $mainBg;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: darken($mainBg, 30);
  }

  @include tablet {
    &.collapsed { 
      .sidebar-close{
        display: none;
      }
    }
  }
  @media (max-width: 991px)   {
    &.collapsed {
      left: -100%;
      transition: all 0.3s; 
    }
    left: 0;
    transition: all 0.5s;
    z-index: 999;
    
   
  }
  .sideBarNav {
    position: relative;
    padding: 15px 0 0 0;
    // margin: 0 0 20px;
    overflow: hidden;

    li {
      padding: 12px 35px;
      position: relative;

      &.active {
        a {
          font-weight: 700;
          color: $white;
          background: $secondaryColor;
          box-shadow: inset 0px 4px 6px 0px darken($secondaryColor, 12);
        }

        &::before {
          content: "";
          position: absolute;
          height: 6px;
          width: 6px;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          background-color: $secondaryColor;
          border-radius: 50%;
          z-index: 1;

          @include tablet {
            display: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          height: 60px;
          width: 60px;
          right: -40px;
          top: 5px;
          transform: rotate(45deg);
          background-color: $bodyBg;
          border-radius: 15px;
          z-index: 0;
          box-shadow: inset 2px 2px 7px 3px lighten($secondaryColor, 32%);

          @include tablet {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 1500px) and (min-width: 992px) {
    &.collapsed {
    width: 120px;
    overflow-x: hidden;
    transition: all 0.3s;
    .sidebar__logo {
      width: 100px;
      justify-content: flex-start;
      overflow: hidden;
      height: 80px;
      padding: 50px 25px;
    }
    .sidebar__menu__item__txt {
      display: none;
    }
    .sideBarNav li {
      padding: 12px 20px;
      display: flex;
      justify-content: center;
      &.active::after { 
        height: 55px;
        width: 55px;
        right: -40px;
        top: 15px; 
    }
    }
    .sidebar__menu__item__icon {
      --size: 55px;
      margin-right: 0;
        img {
        max-height: 50px; 
        }
    }
    .sidebar__menu__item { 
      border-radius: 50%;
      padding: 5px; 
    }
   }
  }
 
  @include tablet {
    // left: -100%;
    // transition: all 0.5s;
    // z-index: 999;
  }

  &__logo {
    @include flex();
    padding: 20px 30px;

    @include tablet {
      justify-content: space-between;
      padding: 50px $mobileSpacing 0;
      margin-bottom: $mobileSpacing;
    }

    img {
      width: 100%;
      height: auto;
    }

    svg {
      width: 275px;
      // for now untill we find a solution to it
      height: auto;
      @media (max-width: 1500px) {
        width: 220px;
      }
      @media (max-width: 576px) {
        width: 200px;
      }
    }

    .sidebar-close {
      cursor: pointer;
      display: none;

      i {
        font-size: 2rem;
      }

      @media (max-width: 1500px)   {
        display: flex;
      }
    }
    @media (max-width: 1500px) {
     justify-content: center;
    }
  }

  &__menu {
    @include flex(stretch, flex-start);
    flex-direction: column;
    flex-grow: 1;

    &__item {
      @include flex(center, flex-start);
      position: relative;
      border-radius: 25px;
      padding: 5px 25px 5px 10px;
      color: $colorLink;
      font-size: 1.1rem;
      transition: color 0.3s ease-in-out;

      @include tablet {
        padding-left: $mobileSpacing;
      }
      @media (max-width: 576px) {
        padding: 5px 10px;
      }
      &:hover {
        color: $secondaryColor;

        @include tablet {
          color: $orange;
        }
      }

      &__icon {
        --size: 40px;
        width: var(--size);
        height: var(--size);
        margin-right: 0.8rem;
        @include flex(center, center);

        @include tablet {
          margin-right: 1rem;
        }

        i {
          font-size: 1.9rem;
        }

        img {
          max-height: 30px;
          max-width: 75%;
        }

        svg {
          font-size: 1.9rem;
        }

        &.client_icon-2 {
          img {
            max-height: 75%;
            max-width: 70%;
          }
        }
      }

      &:last-child {
        margin-top: auto;
        cursor: pointer;
      }
    }
  }
}
.sidebar__logo-smart{
  display: none;
  @media (max-width: 991px) {
    display: flex;
    position: absolute;
    left: 20px;
    top: 0;
    width: 84px;
    height: 82px;
    overflow: hidden;
    z-index: 999;
    align-items: center;
    border-right: 1px solid lighten($primaryColor, 15);
    &:after{
      content: "";
      position: absolute;
      right: 0;
      width: 10px;
      top:0;
      bottom: 0;
      background:#fff;
    }
    svg{
      max-height: 65px;
      width: auto;
    }
  }
  @media (max-width: 420px) {
    left: 13px;
    width: 50px;
    height: 70px;
  
    svg{
      max-height: 40px;
      width: auto;
    }
  }
}
.sidebar-toggle {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 999;
  left: 15px;
  top: 15px;
  @include flex(center, center);
  cursor: pointer;
  display: none;

  i {
    font-size: 3rem;
    color: $primaryColor;
  }

  @media (max-width: 1500px) {
    display: flex;
    background: #fff;
    padding: 14px;
    --size: 50px; 
    border-radius: 10px; 
    left: 145px;
    box-shadow: 0 5px 20px #90cde9;
  }
  @media (max-width: 991px) { 
    box-shadow: none;
    left: 112px;
  }
  @media (max-width: 420px) { 
    left: 67px;
    --size: 40px; 
    i {
      font-size: 2.4rem; 
    }
  }
}

.sidebar-close {
  transform: scale(1) translateX(0);
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 33px;
  border-radius: 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;

  @media (max-width: 1500px) {
    display: flex;
 
 }
}
@media (max-width: 1500px) {
  .sidebar.collapsed .sidebar-close {
      display: none;
  }
}

.sideBar-logout {
  // margin-top: 30px;
  padding: 30px 35px 15px;
  position: relative;

  &::before {
    content: "";
    height: 1px;
    background-color: rgba(176, 176, 176, 0.45);
    top: 0;
    left: 35px;
    right: 35px;
    position: absolute;
  }
}

.sidebar .sideBarNav li.active a {
  .sidebar__menu__item__icon {
    background-size: 100% 30px !important;
    img {
      display: none;
    }
    &.icon-0 {
      background: url(../../assets/images//svg/icon-nav-1-w.svg) no-repeat center;
    }
    &.client_icon-1 {
      background: url(../../assets/images//icons/client/my-trs-white.png) no-repeat center;
      background-size: 90% 80% !important;
    }
    &.icon-1 {
      background: url(../../assets/images//svg/icon-nav-2-w.svg) no-repeat center;
    }
    &.client_icon-0 {
      background: url(../../assets/images//icons/client/dash-white.png) no-repeat center;
      background-size: 75% 75% !important;
    }
    &.icon-2 {
      background: url(../../assets/images//svg/icon-nav-3-w.svg) no-repeat center;
    }
    &.client_icon-2 {
      background: url(../../assets/images//icons/client/invoice-white.png) no-repeat center;
      background-size: 70% 75% !important;
    }
    &.icon-3 {
      background: url(../../assets/images//svg/icon-nav-4-w.svg) no-repeat center;
    }
    &.client_icon-3 {
      background: url(../../assets/images//icons/client/immobs-white.png) no-repeat center;
      background-size: 75% 75% !important;
    }
    &.client_icon-4 {
      background: url(../../assets/images//icons/client/indem-white.png) no-repeat center;
      background-size: 90% 80% !important;
    }
    &.client_icon-5 {
      background: url(../../assets/images//icons/client/bilan-white.png) no-repeat center;
      background-size: 70% 75% !important;
    }
  }
}
