@use "../../../scss/abstracts/" as *;

.card-reporting {
  padding: 40px;

  @media (max-width: 640px) {
    padding: 40px 15px;
    .row {
      .col {
        flex: initial;
      }
    }
  }
}

.actions-footer {
  padding: 50px 15px 15px;
  text-align: center;

  .btn {
    margin: 5px;
    min-width: 150px;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.file-link {
  color: $secondaryColor;
  font-size: 1.1rem;
  font-weight: 900;
  margin-left: 5px;
}

.reporting-companies-list{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}