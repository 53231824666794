@use "../../scss/abstracts/" as *;
.archive_all__errors {
  p {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.client-add-tr-wrapper {
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  .client-add-tr {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 10px;
    padding: 20px 15px;
    // height: calc((100% - 40px) / 2);
    width: 90%;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      height: 50px;
      width: 50px;
      left: -5%;
      top: 50%;
      transform: translateY(-50%);
      background-color: $secondaryColor;
      background: url(../../assets/images/icons/client/tr-add.svg) no-repeat 50% 150%;
      background-size: 80%;
      border-radius: 20px;
      z-index: 1;
      @media (max-width: 576px) {
        height: 45px;
        width: 45px;
        background-size: 70%;
        background-position: 50% 120%;
      }
    }

    &.plus-add {
      color: $secondaryColor;
      &::before {
        background-color: $secondaryColor;
      }
    }

    &.neg-add {
      color: $primaryColor;
      &::before {
        background-color: $primaryColor;
      }
    }

    @media (max-width: 1350px) {
      padding:  15px;
    }
    @media (max-width: 991px) {
      width: 98%; 
  
      &::before { 
        left: 0; 
      }
    }
  }

  @media (max-width: 991px) {
    order: 0;
 
  }

}
@media (max-width: 991px) { 
.client-encais{
  order: 1;
}
.client-estims{
  order: 2;
}
}
.action-top-tabs {
  display: flex;
  padding: 10px 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 29px;
  width: 80%;

  .btn {
    margin: 5px;
    min-height: 55px;
  }
  @media (max-width: 1080px) {
    position: static;
    width: 100%;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
}
