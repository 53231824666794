@use "../../../scss/abstracts/" as *;
@use "sass:math";

.modal-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 15px 15px 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 55px;
  position: relative;
  min-height: 225px;
  &.primary {
    background: lighten($primaryColor, 38);
    color: $primaryColor;
  }
  &.secondary {
    background: lighten($secondaryColor, 38);
    color: $secondaryColor;
  }
  &.warning {
    background: lighten($orange, 38);
    color: $orange;
  }
  .modal-banner__text {
    width: 60%;
    text-align: center;
  }

  .modal-banner__title {
    font-size: 2.5rem;
    font-family: $tt-demibold;
    color: $black;
    margin-bottom: 25px;
    position: relative;
    z-index: 99;
  }

  .modal-banner__img {
    // background: url(../../assets/images/bg-banner-filter.svg) no-repeat bottom;
    background-size: 90%;
    max-width: 280px;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    padding: 0 40px 0 0;
    display: flex;
    align-items: center;

    img {
      max-width: 280px;
      height: auto;

      @media (min-width: 1000px) and (max-width: 1600px) {
        max-width: 320px;
      }
      @media (max-width: 767px) {
        opacity: 0.55;
      }
    }
  }

  p {
    margin: 0px 50px 0 0;
    font-size: 1rem;
    line-height: 20px;
    color: white;
  }
}

.Link {
  text-decoration: underline;
  color: white;
  text-underline-offset: 1px;
}

@media (max-width: 950px) {
  .modal-banner__ {
    flex-direction: column;
  }

  .More {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 0px;
  }
}

@media (max-width: 720px) {
}
