@use "../../scss/abstracts/" as *;

.first__card {
  .first__card__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;

    h2 {
      font-weight: 900;
    }

    button {
      text-align: end;
    }
  }

  .first__card__description {
    color: $secondaryColor;
    background: lighten($secondaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    padding: 15px 20px;
    border-radius: 20px;
    border-color: $secondaryColor;

    &::-webkit-input-placeholder {
      color: lighten($secondaryColor, 10);
    }

    &:-ms-input-placeholder {
      color: lighten($secondaryColor, 10);
    }

    &::placeholder {
      color: lighten($secondaryColor, 10);
    }
  }
}

.second__card {
  display: flex;
  width: 100%;

  .card-vehics {
    width: 33.33%;
    background-color: transparent;
    box-shadow: none;

    .Banner-text {
      h2 {
        color: $black;
      }
    }

    img[alt="icon"] {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 1199px) {
    flex-direction: column;
    .card-vehics {
      margin-bottom: 5px;
    }
    .card-vehics {
      width: 100%;
    }
  }
  @media (max-width: 576px) { 
    .card-vehics {
      margin-bottom: 15px;
      padding: 0;
    }
    
  }
}

.create-indem__button {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  .btn {
    min-height: 55px;
  }

  svg {
    color: $orange;
    font-size: 30px;
  }
}

// .indemnity-selector-wrapper{
//   .Banner-img{
//     img {
//       height: 95%;
//       width: auto;
//     }
//   }
// }

.text-orange {
  color: $orange;
  cursor: pointer;
}
