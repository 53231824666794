@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}
.action-top-nav {
  position: absolute;
  top: -82px;
  right: 75px;
  .btn {
    margin: 0 7px;
  }
  @media (max-width: 991px) {
    position: static;
    text-align: right;
    .btn {
      margin: 0 5px 12px;
    }
  }
}
.btn-creat-user {
  min-width: 240px;
  min-height: 54px !important;
  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}
.btn-creat-lead {
  min-width: 200px;
  min-height: 54px !important;
  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}
.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
  @media (max-width: 991px) {
    padding: 15px 0 10px;
    justify-content: center;
    .btn {
      flex: none;
      min-width: 120px;
    }
  }
}
.block-show-mail {
  padding: 0 15px;
  .box-line-show {
    color: $black;
    text-transform: uppercase;
    font-family: $tt-demibold;
    font-size: 1.15rem;
    line-height: 1.15rem;
    margin-bottom: 0.5rem;
    .label-form {
      text-transform: none;
      color: $secondaryColor;
    }
  }
}
.text-show {
  height: auto !important;
  color: #3b6273 !important;
  padding: 25px;
}
.btn-add-mail {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 99;
  background: $orange;
  color: $white;
  font-size: 1.8rem !important;
  box-shadow: -5px 5px 20px #ceb18f;
  border-radius: 28px !important;
  width: 80px;
  height: 80px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
  svg {
    max-width: 40px !important;
  }
  &:hover {
    box-shadow: none;
  }
}

.label-line {
  min-width: 70px;
}

.email-attach{
 pre{
  max-width: 220px;
  overflow:hidden !important;
  text-overflow: ellipsis;
 }
}
