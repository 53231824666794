@use "../../scss/abstracts/" as *;
.client-create-invoice-wrapper {
  align-items: center;
  .form-icon:first-child {
    margin-right: 1rem;
  }
  .form-icon {
    margin-bottom: 0 !important;
    @media (max-width: 576px) {
      margin-bottom: 10px !important;
    }
  }
  @media (max-width: 576px) {
    .form-icon {
      width: 100%;
      text-align: center;
      margin-right: 0 !important;
    }
  }
}

.choose-invoice-type-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-facture-select {
  .select__control {
    cursor: pointer !important;
    .dropdown-custom-icon {
      width: 60%;
      vertical-align: baseline;
      margin-top: 5px;
    }
  }
  .select__menu {
    cursor: pointer !important;
  }
}
.fill-invoice-modal {
  max-width: 1200px !important;
}

.status-change-modal {
  .bank-wrapper {
    width: 100%;
  }
}

.categ-select {
  .select__control {
    &.select__control--menu-is-open {
      width: 100% !important;
    }
  }
}

.models-view-modal {
  max-width: 1250px !important;
}
