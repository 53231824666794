@use "./variables" as *;

.custom-select {
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  min-width: 125px;
  &.outline {
    .select__control {
      background: $white !important;
    }
  }
  &.small.outline.basic-multi-select,
  &.small.outline {
    .select__control {
      height: 45px;
      min-height: 45px;
      &.select__control--menu-is-open {
        border: none !important;
        border-radius: 25px 25px 0 0 !important;
      }
    }
    .select__indicator {
      height: 43px;
      width: 43px;
      svg {
        width: 23px;
        height: 23px;
      }
    }
    .select__menu {
      padding-top: 10px;
    }
  }
  .select__menu {
    border-radius: 0 0 20px 20px !important;
    padding-top: 30px;
    border: none !important;
    top: 25px !important;
    z-index: 99;
    overflow: hidden;
    .select__menu-list {
      .select__option {
        line-height: 15px;
        transition: all 0.3s ease-in-out;
        &:hover {
          transition: all 0.3s ease-in-out;
        }
      }
    }
    @media (max-width: 1199px) {
      padding-top: 15px;
    }
  }
  &.form-primary {
    .select__control {
      color: $primaryColor !important;
      background: lighten($primaryColor, 35);
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      border-color: $primaryColor;
      .select__input-container,
      .select__single-value,
      .select__placeholder {
        color: $primaryColor !important;
      }
      &.select__control--menu-is-open {
        // border: 1px solid $primaryColor !important;
        border: none !important;
        border-radius: 35px 35px 0 0 !important;
        @media (max-width: 1199px) {
          border-radius: 25px 25px 0 0 !important;
        }
      }
    }
    .select__menu {
      color: $primaryColor !important;
      background: lighten($primaryColor, 35) !important;
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25) !important;
      color: darken($primaryColor, 5) !important;
      ul li a {
        color: darken($primaryColor, 5) !important;
        line-height: 16px;
      }
      .select__menu-list {
        .select__option {
          padding: 8px 10px;
          &:hover,
          &.select__option--is-selected {
            background: $primaryColor;
            color: #fff;
          }
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: $primaryColor;
          border: 0px none $white;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background: $bodyBg;
          border: 1px none $white;
          border-radius: 5px;
        }
      }
    }
    .select__indicator {
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      color: $primaryColor;
    }
    &.outline {
      .select__control {
        border: 1px solid $primaryColor !important;
      }
    }
  }
  &.form-secondary {
    .select__control {
      color: $secondaryColor !important;
      background: lighten($secondaryColor, 35);
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
      border-color: $secondaryColor;
      .select__input-container,
      .select__single-value,
      .select__placeholder {
        color: $secondaryColor !important;
      }
      &.select__control--menu-is-open {
        // border: 1px solid $secondaryColor !important ;
        border: none !important;
        border-radius: 35px 35px 0 0 !important;
        @media (max-width: 1199px) {
          border-radius: 25px 25px 0 0 !important;
        }
      }
    }
    .select__menu {
      color: $secondaryColor !important;
      background: lighten($secondaryColor, 35) !important;
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25) !important;
      color: darken($secondaryColor, 5) !important;
      ul li a {
        color: darken($secondaryColor, 5) !important;
      }
      .select__menu-list {
        .select__option {
          &:hover,
          &.select__option--is-selected {
            background: $secondaryColor;
            color: #fff;
          }
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: $secondaryColor;
          border: 0px none $white;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background: $bodyBg;
          border: 1px none $white;
          border-radius: 5px;
        }
      }
    }
    .select__indicator {
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
      color: $secondaryColor;
    }
    &.outline {
      .select__control {
        border: 1px solid $secondaryColor !important;
      }
    }
  }
  &.form-warning {
    .select__control {
      color: $orange !important;
      background: lighten($orange, 25);
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 15);
      border-color: $orange;
      .select__input-container,
      .select__single-value,
      .select__placeholder {
        color: $orange !important;
      }
      &.select__control--menu-is-open {
        // border: 1px solid $orange !important;
        border: none !important;
        border-radius: 35px 35px 0 0 !important;
        @media (max-width: 1199px) {
          border-radius: 25px 25px 0 0 !important;
        }
      }
    }
    .select__menu {
      color: $orange !important;
      background: lighten($orange, 25) !important;
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 15) !important;
      color: darken($orange, 5) !important;
      ul li a {
        color: darken($orange, 5) !important;
      }
      .select__menu-list {
        .select__option {
          &:hover,
          &.select__option--is-selected {
            background: $orange;
            color: #fff;
          }
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: $orange;
          border: 0px none $white;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background: $bodyBg;
          border: 1px none $white;
          border-radius: 5px;
        }
      }
    }
    .select__dropdown-indicator {
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
      color: $orange;
    }
    &.outline {
      .select__control {
        border: 1px solid $orange !important;
      }
    }
  }
  &.custom-form-warning {
    .select__control {
      color: $orange !important;
      background: lighten($orange, 25);
      box-shadow: none;
      border-color: $orange;
      .select__input-container,
      .select__single-value,
      .select__placeholder {
        color: $orange !important;
      }
      &.select__control--menu-is-open {
        // border: 1px solid $orange !important;
        border: none !important;
        border-radius: 35px 35px 0 0 !important;
        @media (max-width: 1199px) {
          border-radius: 25px 25px 0 0 !important;
        }
      }
    }
    .select__menu {
      color: $orange !important;
      background: lighten($orange, 25) !important;
      // box-shadow: inset 0px 4px 6px 0px lighten($orange, 15) !important;
      color: darken($orange, 5) !important;
      ul li a {
        color: darken($orange, 5) !important;
      }
    }
    .select__dropdown-indicator {
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
      color: $orange;
    }
    &.outline {
      .select__control {
        border: 1px solid $orange !important;
      }
    }
  }
  &.custom-form-danger {
    .select__control {
      color: $red !important;
      background: lighten($red, 25);
      // box-shadow: inset 0px 4px 6px 0px lighten($red, 15);
      border-color: $red;
      .select__input-container,
      .select__single-value,
      .select__placeholder {
        color: $red !important;
      }
      &.select__control--menu-is-open {
        // border: 1px solid $red !important;
        border: none !important;
        border-radius: 35px 35px 0 0 !important;
      }
    }
    .select__menu {
      color: $red !important;
      background: lighten($red, 25) !important;
      // box-shadow: inset 0px 4px 6px 0px lighten($red, 15) !important;
      color: darken($red, 5) !important;
      ul li a {
        color: darken($red, 5) !important;
      }
    }
    .select__dropdown-indicator {
      // box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
      color: $red;
    }
    &.outline {
      .select__control {
        border: 1px solid $red !important;
      }
    }
  }
  .select__control {
    border: none !important;
    min-height: 60px;
    border-radius: 35px !important;
    position: relative;
    &.select__control--menu-is-open {
      z-index: 991;
    }
    @media (max-width: 1199px) {
      min-height: 45px;
    }
  }
  .select__indicator-separator {
    background: transparent;
  }
  .select__dropdown-indicator {
    color: $secondaryColor;
    background: $white;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    border: none;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
    svg {
      width: 23px;
      height: 23px;
    }
    @media (max-width: 1199px) {
      height: 43px;
      width:43px;
    }
  }
  .select__value-container {
    padding: 0 10px 0 15px;
  }
}
.select__multi-value {
  background-color: $white !important;
  border-radius: 15px !important;
  .select__multi-value__remove {
    border-radius: 50% !important;
  }
}
