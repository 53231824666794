@use "../../scss/abstracts/" as *;
@use "sass:math";
.card-dash-box {
  background: #f5f5f5;
  box-shadow: 5px 10px 60px rgba(81, 180, 210, 0.36);
  border-radius: 40px;
  padding: 20px;
  color: $white;
  position: relative;
  height: calc(100% - 30px);
  @media (max-width: 1350px) {
    padding: 15px;
  }
  @media (max-width:991px) {
    height: calc(100% - 15px);
  }
  .box-inner-card {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    h4 {
      color: rgba(255, 255, 255, 0.65);
      font-size: 1.1rem;
    }
  }
  .card-icon {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: -5px 5px 30px rgb(0, 0, 0, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.63);
    border-radius: 25px;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 75px;
      height: auto;
    }
    @media (max-width: 1350px) {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      img {
        max-width: 50px; 
      }
    }
   
  }
  .card-texte {
    width: calc(100% - 85px); 
    padding-left: 12px;
    @media (max-width: 1350px) {
      width: calc(100% - 60px);
    }
  }
  .card-price {
    font-size: 1.4rem;
    line-height: 30px;
    font-family: $tt-demibold;
  }
  .etat-value-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 10px 0 0 30px;
    font-size: 0.95rem;
    @media (max-width: 576px) { 
      justify-content: flex-start;
      padding: 5px 30px 10px 0;
    }
    @media (min-width: 577px) and (max-width: 1350px) { 
      justify-content: flex-end;
      padding: 5px 30px 10px 0;
    }

    
    img,
    svg {
      position: relative;
      top: 2px;
      max-height: 23px;
      width: auto;
    }
    .etat-percent {
      color: rgba(255, 255, 255, 0.65);
      display: inline-block;
      padding: 0 0 0 5px;
    }
  }
  &.card-primary {
    background-image: linear-gradient(
      to right,
      #62d1ba,
      #53c6ae,
      #44bca2,
      #34b196,
      #1fa78a
    );
  }
  &.card-secondary {
    background-image: linear-gradient(
      to bottom,
      #4fadd4,
      #46a5cc,
      #3d9cc4,
      #3494bd,
      #2a8cb5
    );
  }
  &.card-danger {
    background-image: linear-gradient(
      to right,
      #f68578,
      #f57465,
      #f36351,
      #ef503c,
      #eb3a27
    );
  }
  &.card-warning {
    background-image: linear-gradient(
      to right,
      #ffcc54,
      #fec24d,
      #feb946,
      #fdaf40,
      #fca53b
    );
  }
}
