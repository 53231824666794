@use "../../scss/abstracts/"as *;

.top-odt-tva {
  padding: 40px 0 0;

  @media (max-width: 1550px) {
    padding: 40px 15px 0;
  }
  @media (max-width: 576px) {
    padding: 30px 0 0;
  }
}

.odt-pages {
  @media (max-width: 1550px) {
    padding: 0 15px;
  }
  @media (max-width: 576px) {
    padding:   0;
  }
}

.action-top-odt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;

  .month-label {
    font-size: 1.5rem;
    font-family: $tt-demibold;
    color: #000;
    text-transform: uppercase;
  }

  .btn {
    margin-right: 15px;
    min-width: 185px;
    min-height: 50px;

    :first-child {
      margin-right: 0;
    }
  }

  @media (max-width: 576px) {
    .month-label {
      margin-bottom: 15px;
    }

    flex-direction: column;

    .od-tva-wrapper {
      display: flex;
      flex-wrap: wrap;
      .btn {
        min-width: 120px;
        padding: 0 15px;
        margin: 0 5px 12px;
      }
    }
  }
}

.small-btns {
  .btn {
    min-width: 100px;
  }
}

.odt-tva-Table {
  .rdt_Table .rdt_TableHeadRow .rdt_TableCol:last-child {
    justify-content: center;
    min-width: 300px;
  }

  .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 300px;
  }
}

.tva-recif-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 5px;
    fill: $orange;
  }
}

.delcared-odtva {
  color: $primaryColor;
  font-weight: 700;
  font-size: 1.1rem;

  svg {
    fill: $primaryColor;
  }
}

.reported-odtva {
  color: $orange;
  font-weight: 700;
  font-size: 1.1rem;

  svg {
    fill: $orange;
  }
}

.rectifi-popver-body {
  .tooltip-inner {
    text-align: start;
    padding: 10px;
    background-color: $white;
    opacity: 1 !important;
    span{
      color: $orange;
    }

    ul {
      margin: 0;
      padding: 0;
      color: $black;
    }
  }

}