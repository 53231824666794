@use "../../scss/abstracts/" as *;
.client-profile-tabs {
  background: none;
  .tabs-component {
    .tablist {
      button {
        display: flex !important;
        flex-direction: row-reverse !important;
        justify-content: space-around;
        align-items: center;
        .icon-tabs {
          min-height: 130px !important;
          #in-case-img {
            max-width: 70% !important;
            max-height: 70% !important;
          }
          @media (max-width: 1350px) {
            min-height: 90px !important;
            #in-case-img {
              max-width: 90px!important;
              max-height: 90px !important;
            }
          }
          @media (max-width: 479px) { 
            #in-case-img {
              display: none;
            }
          }
        }
        #in-case-text {
         font-size: 2.5rem;
         line-height: 2.5rem;
        }
      }
    }
  }
}

.admin-profile-form {
  padding: 2.25rem 3.5rem;
}

.admin-profile-photo {
  padding: 2.25rem;
  position: relative;
  min-height: 35%;
  justify-content: center;
  button {
    position: absolute;
    top: 90%;
  }
  &::before {
    content: "";
    position: absolute;
    background: $secondaryColor;
    border-radius: 25px;
    transform: rotate(-10deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: -1;
  }
}

.tabpanel {
  text-align: left;
  padding: 1rem;
  @media (max-width: 576px) {
    padding: 1rem 0;
  }
}

