@use "../../../scss/abstracts/" as *;

.section-Compte-sync {
  padding: 25px 0 15px;

  h3 {
    color: #b0b0b0;
    border-bottom: 2px dashed #b0b0b0;
    font-size: 27px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  .btn {
    position: relative;
    &.icon-left {
      padding-left: 75px;
    }

    .icon {
      color: #50b1dc;
      background: #fff;
      height: 58px;
      width: 58px;
      border-radius: 50%;
      position: absolute;
      border: none;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 91;
      left: 1px;
      top: 1px;
      svg {
        position: relative;
        top: 7px;
        max-width: 38px;
      }
      &.icon-primary {
        box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
        color: $primaryColor;
      }
      &.icon-secondary {
        box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
        color: $secondaryColor;
      }
      &.icon-warning {
        box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
        color: $orange;
      }
      &.icon-danger {
        box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
        color: $red;
      }
    
    }
    @media (max-width: 1199px) {
      &.icon-left {
        padding-left: 55px;
      }
      .icon {
        height: 41px;
        width: 41px;
      }
    }
  }
}

.bottom-actions {
  .btn {
    margin: 5px;
  }
}

.blocCode {
  padding: 15px 25px;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  border-radius: 15px;
  border: 2px dashed #b0b0b0;
  position: relative;

  .delete-sync-org-button {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      display: flex;
      align-items: center;
      img,
      svg {
        position: relative;
        top: 5px;
        left: -5px;
      }
      &:hover {
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 15px ;
    .delete-sync-org-button{
      position:absolute;
      bottom: 20px ;
      right: 5px;
      width: auto;
    }
  }
  @media (max-width: 420px) {
    padding: 15px ;
    .delete-sync-org-button{
      .btn{
        padding: 0 5px;
        svg{
          left: 0;
        }
        .delet-span{
          display: none;
        }
      }
    }
  }
}

.sync-modal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: center;
  padding: 0 3rem;
  height: 150px;

  .action-button-wrapper {
    border: 1px dashed $secondaryColor;
    border-radius: 15px;
    padding: 5px;

    button {
      border-radius: 15px;
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
    }
  }
  @media (max-width: 576px) {
    padding: 0 ;
    button{
      line-height: 22px;
    }
  }
}

.acoounts-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 70%;
    height: auto;
  }

  p {
    color: $default;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.text-nb {
  color: #b0b0b0;

  p {
    line-height: 20px;
  }
}

.multiple-sync-wrapper {
  .bank-wrapper {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed $secondaryColor;
    border-radius: 15px;
    padding: 25px;
  }

  .bottom-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
