.letter-page-wrapper {
    border: 1px solid;
    border-radius: 10px;
    padding: 15px;
    border-color: #28a897;
    margin-bottom: 15px;
    height: 1350px;
    position: relative;

    .letter-header-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .letter-header-images {
            display: flex;
            flex-direction: column;
        }

        .letter-header-images-img-1 {
            height: 100%;
            width: 70%;
        }

        .letter-header-images-img-2 {
            margin-left: 5rem;
            height: 50%;
            width: 70%;
        }

        .letter-header-images-img-3 {
            height: 25%;
            width: 25%;
        }

        .letter-header-images-img-4 {
            height: 100%;
            width: 35%;
        }

    }

    .letter-header-subject {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5rem;
    }

    .letter-header-subject-wrapper {
        background: #cfcfcf4d;
        padding: 2rem 5rem;
        border: none;
        border-radius: 10px;
        box-shadow: 5px 5px 15px 5px #cfcfcf4d;
    }

    .letter-header-subject-companyName {
        font-size: 18px;
    }

    .letter-header-subject-client,
    .letter-header-subject-companyAddress {
        font-family: 14px;
    }

    .letter-pragraphs {
        text-align: start;
        margin-top: 10px;
    }

    .letter-pragraphs-custom {
        text-align: start;
        margin-top: 12px;
    }

    .letter-title {
        margin-top: 12px;
        font-size: 18px;
        font-weight: bold;
        text-align: start;
        color: #28a897;
    }

    .letter-sub-title {
        margin-top: 15px;
        font-size: 18px;
        text-align: start;
    }

    .letter-sub-sub-title {
        margin-top: 15px;
        font-size: 14px;
        text-align: start;
        margin-left: 15px;
    }

    .appendix-title-1,
    .appendix-title-2 {
        font-size: 14px;
        font-weight: bold;
        color: #cfcfcf
    }

    .letter-footer {
        font-weight: bold;
        position: absolute;
        bottom: 0;
        padding: 20px;
        right: 0;
    }

    .footer-number-wrapper {
        background: linear-gradient(to right, #3EB5C8, #4DBB99);
        color: #fff;
        margin-left: -5px;
        margin-right: 5px;
        padding: 8px 15px;
        border: none;
        border-radius: 5px;
    }

    .letter-custom-tr {
        text-align: start;
        background: linear-gradient(to right, #3EB5C8, #4DBB99);
        color: #fff
    }

    .letter-custom-td {
        text-align: center;
    }

    .letter-td {
        text-align: start;
    }

    .letter-viewer {
        height: calc(100vh - 150px);
        overflow: auto;
    }

    .letter-header-images {
        justify-content: center;
        align-items: center;
    }

    .letter-header-images-img-2 {
        margin-left: 0;
    }

    .letter-pragraphs {
        line-height: 100%;
    }

    .letter-header-images-img-3 {
        height: 100%;
        width: 100%;
    }
}