.archive_all__errors {
  p {
    font-size: 1.2rem;
  }
}

.action-top-tabs {
  display: flex;
  padding: 10px 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 29px;
  width: 80%;

  .btn {
    margin: 5px;
    min-height: 55px;
  }
  @media (max-width: 1080px) {
    position: static;
    width: 100%;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.immoblisation-selector-wrapper {
  .Banner-img {
    img {
      height: 95%;
      width: auto;
    }
  }
}
